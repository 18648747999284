html {
    scroll-behavior: smooth
}

body {
    font-size: 16px;
    font-weight: normal;
    color: #3e3e3e;
    background-color: #fff;
}

.main-container {
    width: 100%;
    margin: 0 auto;
}


.skin-size {
    /* max-width: 1235px; */
    margin: auto;
    /* text-align: center; */
}

.side-color {
    background-color: #fff;
}

.white {
    color: #fff;
}

.h1,
h1 {
    font-size: 2.5em;
    line-height: 1.25em;
    font-weight: 700;
    letter-spacing: -1px;
    text-align: center;
    color: #E42131;
    font-weight: bold;
}

.h1-small {
    font-size: 40px;
}

h2 {
    font-size: 45px;
    letter-spacing: 0;
    font-weight: bold;
    color: #2D3370;
}


h3 {
    font-size: 24px;
}

.fs-14 {
    font-size: 14px;
}

.fs-16 {
    font-size: 16px;
}

.fs-1em {
    font-size: 1em;
}

.fs-1_25em {
    font-size: 1.25em;
}

.fs-1_50em {
    font-size: 1.50em;
}

.fs-20 {
    font-size: 20px !important;
}

.styleColor {
    color: #2D3370;
}

.bg-blue {
    background: #1a3463;

}

.light-blue {
    color: rgb(148, 163, 184)
}

.bg-light-blue {
    background: rgb(0, 114, 177) !important;
}

.bg-light-grey {
    background: #f2f0f1;
}

.bg-grey {
    background: #e4e4e4;
}

h3 {
    font-size: 30px;
    font-weight: bold;
    width: 100%;
}

small,
.p-small {
    font-size: 18px;
}

h3 .link,
.link-blue {
    color: blue;
}

.bold {
    font-weight: 500;
}

.f18 {
    font-size: 18px;
}

.f22 {
    font-size: 22px;
}

.f22 button {
    font-size: 22px !important;
    font-weight: 900;
}

.main-nav-center {
    flex: 6;
}

.navbar {
    background: white;
    /* box-shadow: 0px 4px 20px rgba(25, 59, 104, 0.25); */
    padding-top: 0;
    padding-bottom: 0;
}

.navbar-brand img {
    width: 250px;
    height: 100%;
}

.navbar-light .navbar-nav .nav-link {
    color: #3e3e3e !important;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    text-decoration: none;
    border: 0;

}

.nav-link {
    /* padding: 0.5rem 2rem 0.5rem !important; */
}

.navbar-nav {
    justify-content: space-between;
}

input:focus,
select:focus,
textarea:focus,
button:focus {
    outline: none !important;
    outline-width: 0 !important;
    box-shadow: none;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
}

.navbar-light .navbar-nav .nav-link:focus,
.navbar-light .navbar-nav .nav-link:hover {
    background: transparent;
    border: 0;
}

.navbar-light .navbar-nav .active>.nav-link,
.navbar-light .navbar-nav .nav-link.active,
.navbar-light .navbar-nav .nav-link:focus,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .show>.nav-link {
    color: #3e3e3e;
    border-bottom: none;
    /* outline: 2px solid #007bff !important; */
}

.nav-item {
    display: flex;
    align-items: center;

}

.show .nav-item .nav-link,
.btn-primary-mobile-header {
    font-size: clamp(0.7rem, -0.2rem + 2.5vw, 2rem) !important;
}

.nav-link-custom .nav-item-custom {
    color: #3e3e3e;
}

ul.dropdown-menu {
    padding: 0;
    box-shadow: 0px 4px 20px rgba(25, 59, 104, 0.25);
}

.navbar-nav .dropdown-menu {
    border: 2px solid #3e3e3e;
    border-radius: 0;
}

.navbar-divide-bot {
    border-bottom: 2px solid #3e3e3e;
}

.navbar-divide-top {
    border-top: 2px solid #3e3e3e;
    margin-top: 2px
}

.navbar-nav .dropdown-menu .dropdown-item {
    padding: 0;
}

.dropdown-item.active,
.dropdown-item:active {
    background-color: #fff;
    color: #3e3e3e;
}

.dropdown-toggle::after {
    display: none;
}

.user-menu-icon {
    border: 0;
    background: none;
}

.userprofile-icon {
    width: 40px;
    vertical-align: text-bottom;
}

.dd-item-icon {
    width: 32px;
    padding-right: 6px;
}

.user-menu ul.dropdown-menu {
    padding-bottom: 15px;
}

.user-menu .dropdown-item a,
.user-menu .dropdown-item button {
    text-decoration: none;
    display: block;
    text-align: left;
    color: #3e3e3e;
    line-height: 24px;
    padding-left: 0;
    width: 100%;
}

.user-menu .dropdown-menu button.btn-profile {
    padding: 0;
    outline: none;
    border: none;
}

.carousel-control-prev,
.carousel-control-next,
.carousel-indicators {
    display: none;
}

.asterisk:after {
    content: "**";
    width: 5px;
    height: 5px;
    color: #606060;
    font-weight: bold;
}

.visible-hidden {
    opacity: 0;
    display: none;
}

.v-align-baseline {
    align-items: baseline;
}

.form-group {
    display: block;
    margin-bottom: 0.75rem;
    /* text-transform: capitalize !important; */
}

.col-form-label {
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 1px;
    /* text-transform: capitalize !important; */
}

.form-group .form-control {
    color: #606060;
    border: none;
    height: 36px;
    border-radius: 0px;
    font-size: 18px;
    padding: 25px 15px;
    outline: 0;
}

.form-control:focus {
    border-color: #3e3e3e;
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
}

.form-group .form-control::placeholder {
    /* color: #fff; */
    font-size: 18px;
    font-weight: 300 !important;
}

.form-group .show-placegholder::placeholder {
    color: #bebebe !important;
    font-weight: 300 !important;
}

.agreewith {
    margin-top: 0.5rem;
    margin-left: 10px;
}

.main-heading {
    margin: 20px 0px 50px 0px !important;
}

.sub-heading {
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    padding-bottom: 60px;
}

.sub-heading a {
    text-decoration: underline;
}

.skiptocontent {
    position: absolute;
    opacity: 0;
    left: 50%;
    top: 0;
    transition: -webkit-transform .166s;
    transition: transform .166s;
    transition: transform .166s, -webkit-transform .166s;
    -webkit-transform: translate(-50%, -110%);
    -ms-transform: translate(-50%, -110%);
    transform: translate(-50%, -110%);
}

.btn-primary {
    margin-top: 20px;
    background: #E42131;
    /* text-transform: capitalize !important; */
    color: #FFFFFF;
    font-size: 15px;
    line-height: 15px;
    border: none;
    border-radius: 0;
    min-width: 160px;
    letter-spacing: 1px;
    padding: 14px 40px;
    text-decoration: none;
    /* border-radius:50px; */
}

.btn-primary-header {
    padding: 10px 20px;
    text-transform: uppercase;
    margin: 0.5em 0em 0.5em 0;
    font-size: clamp(1rem, -0.875rem + 2.5vw, 2.5rem);
    line-height: normal !important;
}

.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active,
.btn-primary:not(:disabled):not(.disabled):active {
    background: #991520;
    color: #fff;
    /* border: 1px solid #3e3e3e; */
}

.btn-com-action {
    width: 100%;
    margin-top: 20px;
    background: #FFFFFF;
    /* text-transform: capitalize !important; */
    color: #E42131;
    font-size: 16px;
    line-height: 24px;
    border: none;
    border-radius: 0;
    min-width: 160px;
    letter-spacing: 1px;
    padding: 10px 90px 10px 20px;
    text-decoration: none;
    position: relative;
}

.btn-com-action:hover,
.btn-com-action:focus,
.btn-com-action:active,
.btn-com-action:not(:disabled):not(.disabled):active {
    color: #E42131;
}

.btn-com-action img {
    width: 50px;
    height: 40px;
    right: 40px;
    position: absolute;
    top: 50%;
    bottom: 50%;
    margin-top: auto;
    margin-bottom: auto;
    object-fit: contain;
}

.toggle-banner-img {
    padding: 0;
    margin-top: -1px;
}

.control-disabled:disabled {
    color: #666 !important;
}


.btn-link {
    color: #3e3e3e;
    text-decoration: underline;
    font-size: 18px;
}

a {
    color: #3e3e3e;
    text-decoration: underline;
}

a:hover,
a:focus {
    color: #3e3e3e;
    text-decoration: none;
}

.btn-light {
    color: #3e3e3e;
    font-size: 18px;
    line-height: 24px;
    text-transform: uppercase;
    transition: ease 0.3s;
}

.btn-light:hover,
.btn-light:focus {
    background-color: #3e3e3e;
    color: #fff;
}

.card {
    border: 1px solid #D9D9D9;
    border-radius: 30px;
    padding: 20px;
}

.faq-page .card {
    padding: 0;
    margin: 1px 0;
    border: none;
    border-radius: 0;
    background: #E42131;
    color: #fff;
}

.faq-page .card-header {
    padding: 5px 15px
}

.faq-page .nav-link {
    padding: 20px 0 !important;
}

.faq-line-icon {
    color: #fff;
    list-style: 32px;
    font-size: 22px;
    font-weight: bold;
}

.faq-page h2 {
    font-size: 21px;
    margin-bottom: 10px;
    letter-spacing: -0.5px;
    font-weight: 400;
}

.faq-page .p-small {
    font-size: 21px;
    letter-spacing: -0.5px;
}

.faq-page .card-body {
    font-family: "roboto";
    padding: 2rem 1.25rem;
    border: none;
    color: #606060;
    background-color: #fff;
    border-top: transparent;
}

.same-height {
    display: flex;
}

.alink {
    color: #606060;
    text-decoration: underline;
}

.alink:hover,
.alink:focus {
    color: #3e3e3e;
    text-decoration: underline;
}

.white-link {
    color: #fff !important;
    text-decoration: underline;
}

.white-link:hover,
.white-link:focus {
    color: #fff;
    text-decoration: underline;
}

.remove-underline,
.remove-underline span {
    text-decoration: none !important;
    border: none !important;
    outline: none !important;
}

.remove-underline:hover,
.remove-underline span:hover,
.remove-underline span:focus {
    text-decoration: none !important;
    cursor: pointer;
}

*:focus {
    outline: 0 !important;
}

.remove-underline::-moz-focus-inner,
.remove-underline span::-moz-focus-inner {
    border: 0;
}

/* reward page */
.rewards-page .reward-title {
    text-transform: uppercase;
    font-size: 18px;
    margin-top: 5px;
    font-weight: bold;
    color: #3e3e3e;
}

.cursor-pointer {
    cursor: pointer;
}

.rewards-page-modal .form-group .form-control {
    border-bottom: none;
    padding-left: 15px;
    height: 46px;
}

.card-cta .card {
    padding: 0;
}

.card-cta .card:hover {
    background-color: #fff;
    border-color: #738eb6;
    cursor: pointer !important;
}

.card-cta img {
    border-radius: 20px;
    margin: 15px 0 0;
}

.card-p {
    padding: 10px 20px;
}


.thankspage {
    color: #3e3e3e !important;
}

.thankspage h2 {
    font-size: 30px;
    font-weight: 200;
    text-align: center;
}

/* footer */
footer {
    background-color: rgb(42, 42, 43);
    color: #fff;
    font-size: 18px;
}

.footer {
    padding-top: 32px;
    padding-bottom: 32px;
}

footer a,
footer .btn {
    color: #fff;
    transition: 0.5s;
    text-decoration: none;
}

footer .btn:hover {
    color: #fff;
}

.footer-link li a,
footer li .btn {
    color: #fff;
    font-size: 1rem;
    padding-right: 0.5rem;
}

.footer-link li a:hover,
.footer-link li a:focus {
    color: #fff;
    text-decoration: underline;
}

.footer-logo_wrap {
    justify-content: center;
    display: flex;
}

.footer-logo_wrap img {
    width: 100px;
    height: auto;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}

/* .social-links{justify-content: left !important;}
.footer .footer-link{justify-content: left !important;} */
.footer .footer-link ul {
    padding-left: 0;
    text-align: center;
}

.footer .footer-link ul li {
    padding: 0 6px;
    display: inline-block;
    line-height: 24px;
    float: inherit;
}

.footer .footer-link ul li a[type=button] {
    color: #fff;
}

.footer .footer-link ul li a[type=button]:hover,
.footer .footer-link ul li a[type=button]:focus {
    color: #fff;
    text-decoration: none;
}


.footer-social li {
    padding: 0;
}

.footer-social li a {
    padding: 5px 15px;
    font-size: 22px;
    color: #fff;
}

.footer-social li a:hover,
.footer-social li a:focus {
    background: none;
}

.footer-bot {
    box-shadow: 0px 4px 20px rgba(25, 59, 104, 0.15);
    padding-top: 12px;
}

button.poplink {
    color: #fff;
    font-size: 18px;
    padding: 0px 0 3px;
}

button.poplink:hover {
    color: #1ea8e0;
    text-decoration: none;
}

.footer-bot .footer-link {
    justify-content: right !important;
    color: #fff;
}

.copy-text {
    margin: 0 0 10px !important;
    text-align: left;
}


.tierlogic a {
    line-height: 30px;
}

.tierlogic img {
    max-height: 30px;
}

/* end footer */


.input-error {
    margin-top: 4px;
    color: #b00924 !important;
    background-color: #e4e4e4 !important;
    font-size: 18px;
}

.portal-point-list-container {
    padding: 40px 0px 40px;
}

.portal-point-list-container .label,
.header-parent-label {
    color: #33333b;
    border-bottom: none;
    text-align: center;
    font-size: 35px;
}

.section-content {
    margin: 40px auto;
}

.section-heading {
    margin-bottom: 30px;
}

.htp-content {
    margin-bottom: 30px;
}

.custom-tooltip-wrap .btn {
    min-width: 26px;
    padding: 0;
    height: 26px;
    border-radius: 80px;
    position: relative;
    left: 6px;
    font-size: 12px;
    margin: 0;
    top: -3px;
}

.inline-checkboxes {
    display: flex;
    align-items: flex-start;
}

.inline-checkboxes label {
    margin-top: -5px;
    margin-left: 10px;
}

.form-check-input {
    margin-top: 0.3rem !important;
    margin-left: -0.9rem;
    height: 16px;
    width: 16px;
    border-radius: 0;
}

.form-check-label {
    margin-left: 14px;
}

ul.listing-points {
    margin: 20px 0;
    padding: 0;
}

ul.listing-points li {
    margin: 10px 0;
    padding: 5px 0px;
    text-align: justify;
}


/* after login */
.table-bordered td {
    border: none;
}

.table-bordered tbody tr:first-child td {
    /* padding-top: 30px; */
}

.table-bordered tbody tr:last-child td {
    /* padding-bottom: 30px; */
}

.mya_table table thead {
    background-color: #3e3e3e;
    color: #FFFFFF;
    font-weight: normal !important;
}

.pagination-block {
    display: flex;
    align-items: center;
    padding-top: 15px;
}

.pagination-block .pagination.react-bootstrap-table-page-btns-ul {
    float: none;
}

.page-item.disabled .page-link {
    text-decoration: none;
}

.page-item.active .page-link {
    background-color: #3e3e3e;
    border-color: #3e3e3e;
    text-decoration: none;
}

.pagination>.active>a:hover {
    background-color: #0e6eb4;
    border-color: #0e6eb4;
}

.pagination .disabled a {
    color: #6c757d;
    pointer-events: none;
    cursor: auto;
    background-color: #fff;
    border-color: #dee2e6;
}

.pagination>li>a {
    border: 1px solid #3e3e3e;
}

.pagination .previous a {
    border-radius: 4px 0 0 4px !important;
}

.pagination .next a {
    border-radius: 0 4px 4px 0 !important;
}

.page-tab_nav a {
    color: #3e3e3e;
    font-size: 18px;
    line-height: 24px;
    text-decoration: none;
    font-weight: bold;
    letter-spacing: 1px;
    border: none;
    min-width: 160px;
    padding: 10px 40px 10px;
}

.active-page {
    background: #3e3e3e !important;
    color: #fff !important;
    border-radius: 4px;
}

.dropzone-div {
    background: #efeeee;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-left: 0;
    margin-right: 0;
    border-width: 3px;
}

.modal-content {
    border-radius: 3rem;
    background-color: #E42131;
    color: #fff;
    padding: 1.5rem .8rem;
    font-weight: normal;
}

.modal-content .btn-link {
    color: #fff;
}

.modal-heading {
    font-size: 22px;
}

.modal-content .btn-modal {
    background-color: #fff;
    color: #3e3e3e;
}

.modal-content a {
    color: #1ea8e0;
}

.modal-content .section-content {
    margin: 0 auto;
}

.close {
    color: #fff;
    opacity: .7;
    text-shadow: 0 1px 0 #3e3e3e;
}

.close:hover {
    color: #D9D9D9;
}

ul.checkmark {
    margin: 0;
    padding: 0;
}

ul.checkmark li {
    font-size: 16px;
    margin-bottom: 1em;
    list-style-type: none;
    padding: .25em 0 0 2.5em;
    position: relative;
}

ul.checkmark li:before {
    content: " ";
    display: block;
    border: solid .8em #1ea8e0;
    border-radius: .8em;
    height: 0;
    width: 0;
    position: absolute;
    left: .5em;
    top: 35%;
    margin-top: -.5em;
}

ul.checkmark li:after {
    content: " ";
    display: block;
    width: .3em;
    height: .6em;
    border: solid #fff;
    border-width: 0 .2em .2em 0;
    position: absolute;
    left: 1.2em;
    top: 38%;
    margin-top: -.2em;
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
}

.participate-page .input-group {
    height: 45px;
}

.participate-page .input-group .input-group-text {
    border-radius: 20px;
    margin-right: -15px;
    z-index: 21;
}

.participate-page .input-group .form-control {
    border-top-right-radius: 20px !important;
    border-bottom-right-radius: 20px !important;
    padding-left: 1.2rem;
}

.participate-page .input-group input::placeholder {
    padding-left: 10px;
}

.participate-page .input-group .search-btn {
    position: absolute;
    z-index: 5;
    right: 10px;
    display: flex;
    /* margin: auto auto; */
    align-items: center;
    justify-content: center;
    top: 50%;
    bottom: 50%;

}

.md-36 {
    font-size: 36px;
}

.card-image {
    object-fit: cover;
    position: relative;
    background-color: #eeeeee;
}

.card-container {
    padding: 20px 37px;
    padding-bottom: 60px;
}

.card-container .d-flex {
    background-color: #eeeeee;
    padding: 0px 70px;
}


/* .card-img-1 img {
    padding-top: 28px;
}

.card-img-2 img {
    padding-top: 47px;
}

.card-img-3 img {
    padding-top: 27px;
} */


.mob-menu-pos {
    float: right !important
}


.react-bootstrap-table table {
    table-layout: auto;
}


.contentinfo {
    background-color: #fff;
}

.footer-link ul {
    margin-top: 20px;
    color: #FFFFFF;
    font-size: 18px;
    line-height: 24px;
}

.copy-text {
    text-align: center;
}

.tierlogic {
    margin-bottom: 0px !important;
}

main {
    background-color: #fff;
}

.asterisk:after {
    color: #b00924 !important;
}


.blue {
    color: #004B7A;
}

.blue:hover {
    color: #004B7A;
    cursor: pointer;
}

.bg-gray-500 {
    background-color: #b6b4b6;
}

.MuiInputBase-input {
    background: #fff !important;
}

.form-control::placeholder,
::-webkit-input-placeholder,
::-moz-placeholder,
:-ms-input-placeholder,
:-moz-placeholder,
::placeholder,
input:-moz-placeholder {
    color: #fff !important;
    opacity: 0 !important;
}

.signup-form .form-group .MuiFormControl-root .MuiInputLabel-outlined {
    font-size: 16px;
    transform: translate(14px, 32px) scale(1);
}

.signup-form .form-group .MuiFormControl-root .MuiInputLabel-shrink {
    transform: translate(14px, -5px) scale(0.90);
}

.signup-form .form-group .MuiFormControl-root .Mui-focused {
    color: #3e3e3e;
}

.signup-form .form-group .MuiFormControl-root input {
    padding: 15px;
    /* font-size: 24px  */
}

.signup-form .form-group .MuiFormControl-root .MuiSelect-root {
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.signup-form .pr .MuiFormControl-root .MuiSelect-root {
    padding-left: 10px;
    justify-content: flex-start;
}

.signup-form .form-group .MuiFormControl-root .MuiSelect-icon {
    top: calc(50% - 25px);
    font-size: 3rem;
    right: -5px;
}

.h-20 {
    height: 20px;
}

.h-10 {
    height: 10px;
}

.black {
    color: #3e3e3e !important;
}

.uppercase {
    text-transform: uppercase;
}

.font-30px {
    font-size: 30px !important;
}

.font-20px {
    font-size: 20px !important;
}

.font-10px {
    font-size: 10px !important;
}

.fs-18 {
    font-size: 18px;
}



.letter-pacing-1 {
    letter-spacing: -1px;
}

.ctg-heading {
    position: relative;
    overflow: hidden;
    text-align: center;
    color: #3e3e3e;
    width: 100%;
    font-size: 1.825rem;
}

.ctg-heading:before,
.ctg-heading:after {
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto 0;
    overflow: hidden;
    width: 48%;
    height: 2px;
    content: '\a0';
    background-color: #cccccc;
    margin-left: 2%;
}

.ctg-heading:before {
    margin-left: -50%;
    text-align: right;
}

.product-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
}

.logo-item {
    width: 135px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    margin: 5px 0 10px 0;
}

.logo-item img {
    object-fit: cover;
    width: 102px;
}

.logo-item .p-small {
    font-size: 10px;
    line-height: 1;
}


.products-items {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.search-result .table-bordered td,
.table-bordered th {
    border: 1px solid #dee2e6;
}

.search-result .table td,
.table th {
    padding: 0.6rem;
    vertical-align: top;
    font-size: 0.8em;
    border-top: 1px solid #dee2e6;
}


.themeColor {
    color: #E42131;
}

.uploaded-receipt-list {
    font-size: 16px
}

.uploaded-receipt-list thead {
    background-color: #192385;
    color: #fff;
}

.uploaded-receipt-list th,
.uploaded-receipt-list td {
    padding: 0.5rem
}

.welcome-img-container .bg-grey {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
    height: 100%;
}

.welcome-img-container .img-1 img {
    width: 75%;
}

.welcome-img-container .img-2 img {
    width: 50%;
}

.welcome-img-container .img-3 img {
    width: 50%;
}

.step {
    display: flex;
    justify-content: space-between;
}

.step p {
    width: 85%;
}

.step .btn-span {
    background: #E42131;
    color: #FFFFFF;
    font-size: 16px;
    line-height: 24px;
    border: none;
    border-radius: 0;
    min-width: 160px;
    letter-spacing: 1px;
    padding: 14px 40px;
    text-decoration: none;
    margin: 0;
    width: 90%;
    text-align: center;
}

.circle {
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 15px;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    color: #fff;
    background-color: #192385;
    margin-right: 10px;
}

.circle-40 {
    width: 40px;
    height: 40px;
}

.img-modal .modal-content {
    border-radius: 0.25rem;
    background-color: #fff;
    color: #3e3e3e;
    padding: 1rem;
    font-weight: normal;
}

.img-modal .modal-header .close {
    background-color: #fff;
    color: #3e3e3e;
}

.bg-dark-blue {
    background-color: #192385;
}

.dark-blue {
    color: #192385;
}

.select-lable {
    border: 2px solid;
    background: white;
    padding: 8px 5px;
    font-size: 16px;
}

.upload-note {
    font-size: 11px;
}


.upload-btn-video {
    width: 100%;
    margin-top: 20px;
    background: #FFFFFF;
    /* text-transform: capitalize !important; */
    color: #E42131;
    font-size: 16px;
    line-height: 24px;
    border: none;
    border-radius: 0;
    min-width: 160px;
    letter-spacing: 1px;
    text-decoration: none;
    position: relative;
    width: fit-content;
}

.upload-btn-video:hover,
.upload-btn-video:focus,
.upload-btn-video:active,
.upload-btn-video:not(:disabled):not(.disabled):active {
    color: #E42131 !important;
}

.upload-btn-video img {
    width: 50px;
    height: 40px;
}

.validate-purchase-div h3 {
    font-size: 25px;
}

.thankspage h1,
.thankspage h2 {
    color: #3e3e3e;

}

.not-register {
    background: rgb(255 255 255 / 90%);
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

.Toastify__toast-container--top-center {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.MuiSelect-select.MuiSelect-select {
    justify-content: left !important;
    padding-left: 10px;
}

/* Home Page */
.custom-home-h1 {
    border: 0;
    border-bottom-width: 8px;
    border-color: rgb(185, 28, 28);
    border-style: solid;
    font-weight: bolder;
}

.image-container img {
    margin-top: 70px;
}

.image-container img:nth-child(1),
.image-container img:nth-child(3) {
    margin-bottom: 70px;
}

.reward-card-container img:hover {
    transform: scale(1.1);
    transition: transform 330ms ease-in-out;
    cursor: pointer;
}

@media (max-width: 997px) {
    .main-container {
        width: 100%;
        margin: 0 auto;
    }
}

@media (min-width: 576px) {
    .mobile-hide {
        display: none !important;
    }

    .btn-com-action {
        width: 80%;
    }

    .toggle-banner-img {
        padding-right: 15px;
        padding-left: 15px;
    }

}

@media (min-width: 768px) {
    .btn-com-action {
        width: 70%;
    }

}

@media (min-width: 992px) {
    .navbar-expand-lg .navbar-collapse {
        /* margin-left: 50px; */
    }

    .btn-com-action {
        width: 50%;
    }
}

@media (min-width: 1200px) {
    .btn-com-action {
        width: 30%;
    }
}

@media (max-width: 768px) {
    .user-pos {
        position: absolute;
        right: 100px;
        top: 10px;
    }
}

@media (max-width: 575.98px) {
    .btn-com-action {
        width: 90%;
    }

    .desktop-hide {
        display: none !important;
    }

    .btn-primary-header {
        padding: 5px 5px;
    }

    .page-tab_nav .col {
        padding-left: 5px;
        padding-right: 5px;
    }

    .page-tab_nav a {
        min-width: auto;
        padding: 10px;
        display: inline-block;
    }

    .btn-primary {
        min-width: 180px;
    }

    .upload-cta .btn-primary {
        min-width: 150px;
        padding-left: 20px;
        padding-right: 20px;
    }

    .btn-secondary {
        max-width: 100px !important;
        min-width: 100px;
        padding-left: 10px;
        padding-right: 10px;
        margin-left: 35px;
    }

    .form-check-input {
        margin-top: 0.3rem !important;
        height: 20px;
        width: 20px;
    }


    .navbar {
        padding: 8px 0;
    }

    .navbar .navbar-toggler {
        margin-right: 16px;
    }

    .user-menu {
        margin-left: 16px;
        width: 100%;
    }

    .user-menu .dropdown-menu {
        min-width: 260px;
    }

    .user-pos {
        position: absolute;
        right: 80px;
        top: 10px;
    }

    .mob-menu-pos {
        float: right !important
    }

    .participate-page .input-group {
        height: auto;
    }

    .participate-page .input-group .input-group-prepend {
        width: 100%;
        margin: 0;
        display: block;

    }

    .participate-page .input-group input::placeholder {
        text-align: center;
    }

    .participate-page .input-group .input-group-text {
        margin: 0;
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        justify-content: center;
    }

    .participate-page .input-group .form-control {
        border-top-left-radius: 0 !important;
        border-top-right-radius: 0 !important;
        border-bottom-left-radius: 20px !important;
        border-bottom-right-radius: 20px !important;
    }

    .participate-page .input-group .search-btn {
        bottom: 0;
    }

    .participate-page .PARTICIPANTES_CONTENT .w-75 {
        width: 100% !important;
    }


    .card-container {
        padding: 0;
        padding-bottom: 60px;
    }

    .card-container .d-flex {
        padding: 60px 30px !important;
        row-gap: 70px;
    }


    .logo-item {
        width: 22vw !important;
    }

    .logo-item img {
        width: 20vw !important;
    }

    .expired h1 {
        font-size: 22px;
    }

    .expired p {
        font-size: 18px !important;
    }

}

@media (max-width: 997px) {
    .upload-btn {
        display: block !important;
        margin: auto !important;
        width: 100% !important;
        text-align: center !important;
    }

}

@media screen and (max-width: 991px) {
    /* #button-navbar-toggler {
        margin: 0 auto;
    } */

    .navbar-light .navbar-nav .nav-link {
        margin-top: 5px;
        margin-bottom: 5px;
        padding-top: 8px;
        padding-bottom: 8px;
        line-height: 24px !important;
        font-size: 15px;
        /* margin-left: 25px; */
        color: #fff;
    }

    .navbar-collapse {
        margin-top: 30px;
    }

    /* 
    .navbar-light .navbar-toggler-icon {
        background-image: url("/public/images/black-hamburger.png");

    } */

    .navbar-toggler {
        border: 0px transparent !important;
        color: transparent !important;
    }
}



/* login btn */
.login-btn {
    background-color: rgb(31, 41, 55)
}

.active-light-blue {
    color: rgb(8, 145, 178)
}